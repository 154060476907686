/* Landing for Listing*/
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.landing-container {
    font-family: 'Arial', sans-serif;
    padding: 30px;
    text-align: center;
    background: linear-gradient(135deg, #f8eef4, #db9eba);
}

.header-primary {
    color: #563138; /* Clio Purple */
    font-size: 3rem;
    margin-bottom: 40px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    font-family: "Lato"
}

.header-secondary {
    font-size: 2.2rem;
    margin: 40px 0;
    color: #333;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    font-family: "Lato";
}

.info-text {
    font-size: 1.2rem;
    margin: 20px 0;
    color: #555;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Lato";
}

.description {
    font-size: 1.5em;
    line-height: 1.6; /* Better line-height for readability */
    margin-bottom: 30px; /* Space between paragraphs */
    color: #333;
    font-family: "Lato";
}

.btn-primary,
.btn-secondary {
    display: inline-block;
    padding: 12px 25px;
    margin: 25px 0;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 1.1rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.btn-primary {
    background-color: #563138; /* Clio Purple */
    font-family: "Lato";
    color: #fff;
}

.btn-secondary {
    background-color: #ddd;
    color: #333;
}

.btn-primary:hover,
.btn-secondary:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
}

.landing-container .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }   
  
.landing-container .video-container iframe {
    border: none; 
  }

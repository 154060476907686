/* CaregiverResources.css */
.caregiver-resources {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px; /* Added 20px horizontal padding for spacing */
    background-color: lavenderblush;
    color: #582c2c;
    line-height: 1.6;
  }
  
  .caregiver-resources-title {
    font-size: 3em;
    color: #563138;
    margin-bottom: 1em;
    transition: all 0.3s ease-in-out;
    text-align: center;
  }

  .caregiver-resources h2 {
    font-size: 2em;
    color: #563138;
    margin-bottom: 1em;
    transition: all 0.3s ease-in-out;
    text-align: left;
  }

  .caregiver-resources h3 {
    font-size: 1.5em;
    color: #563138;
    margin-bottom: 1em;
    transition: all 0.3s ease-in-out;
    text-align: left;
  }
  
  .caregiver-resources-content {
    font-size: 1.5em;
    margin-bottom: 2em;
    transition: all 0.3s ease-in-out;
    text-align: justify;
    line-height: 1.8;
  }
  
  .caregiver-resources-content:last-child {
    margin-bottom: 0;
  }
  
  .caregiver-resources-list {
    list-style: none;
    padding: 0;
    text-align: center; /* Center align list */
    font-size:1.3em;
  }
  
  .caregiver-resources-list li {
    display: inline-block; /* Adjust for center alignment */
    margin: 0 10px 10px; /* Add horizontal margin */
  }
  
  .caregiver-resources-list a {
    color: #985a65;
    transition: color 0.3s ease-in-out;
  }
  
  .caregiver-resources-list a:hover {
    color: #582c2c;
  }
  
  .scroll-animation {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  
  .scroll-animation.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  @keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .caregiver-resources-content, .caregiver-resources-list {
      text-align: left;
    }
  }
  
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    
}

.community-calendar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-event-section {
    display: flex;
    flex-direction: column; /* Ensure items stack vertically */
    align-items: center; /* Center the button horizontally within this section */
}

.create-event-btn {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #935561;
    color: white;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.events{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.react-calendar__navigation button {
    color: #563138;
}

.react-calendar__month-view__days__day--weekend {
    color: #563138; 
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #563138; 
    color: white; 
}

.react-calendar__tile--active {
    background: #563138; 
    color: white; 
}

.react-calendar__tile--now {
    background: lavenderblush; 
    color: #563138; 
    }
    
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #563138; 
    color: white; 
}


.react-calendar__tile--now.react-calendar__tile--active {
background: #563138; 
color: white;
}

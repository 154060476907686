.event-preview {
    background-color: #563138;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    color: white;
    overflow: hidden; /* Ensures smooth transition */
}

.event-basic-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.event-additional-info {
    margin-top: 10px;
    background-color: #563138;
    padding: 5px;
    border-radius: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.event-preview.open .event-additional-info {
    max-height: 500px; /* Estimate this value based on your content */
    transition: max-height 0.5s ease-in-out;
}

.details-toggle-btn {
    width: 30px;
    background-color: white;
    color: #563138;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 16px;
    transition: transform 0.3s ease; /* Animation for spinning */
}

.details-toggle-btn:hover {
    background-color: #f5f5f5;
}

.details-toggle-btn:active {
    transform: rotate(180deg); /* Rotate button when clicked */
}

.event-preview a {
    color: lavenderblush;
    text-decoration: none;
}

.event-preview a:hover {
    text-decoration: underline;
}

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");



:root {
	--mainColor: white;
	--mainColorLight: #5767aa;
	--secondaryColor: rgb(182, 153, 163);
    --headerColor: #563138;
	--textColor: #563138;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	
	background-color: var(--mainColor);
	color: var(--textColor);
    font-size: 1.5rem;
	border-bottom: 3px solid #563138;
    
}

nav a{
	margin: 0 0.8rem;
	color: var(--textColor);
	text-decoration: none;
    
    
}

/*
nav .logButton {
	background: none;
	border: var(--primary);
	border-radius: 4px;
	cursor: pointer;
	text-decoration: none;
	margin: 0 1rem;
	color: var(--textColor);
	font-size: 1.5rem;
	
  }
*/
nav a:hover{
	color: var(--secondaryColor);
}

header .nav-btn{
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 2.0rem;
    float:right;
}

header div,
nav {
    z-index: 9999;
	display: flex;
	align-items: center;
}



/* Dropdown styles */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%; /* Center the dropdown */
    transform: translateX(-50%); /* Adjusts for the centering */
    background-color: #563138;
    min-width: 250px; /* Increased width */
    z-index: 2000;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 10px; /* More rounded corners */
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-content a {
    color: #f1f1f1;
    padding: 12px 20px; /* Added more horizontal padding */
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease, border-radius 0.3s ease;
    border-radius: 8px; /* Rounded corners for the hover effect */
}

.dropdown-content a:hover {
    background-color: #854d58;
	color:rgb(252, 213, 244);
    border-radius: 8px; /* Rounded corners for the hover effect */
}

.dropdown:hover .dropdown-content {
    display: block;
    opacity: 1;
    transform: translateY(0);
}
.dropbtn {
    background-color: transparent;
    color: var(--textColor);
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0;
    margin: 0 0.8rem;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 998;  /* This ensures user-info is below the dropdown */
}

.logout-btn {
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--textColor);
    font-size: 1.5rem;
    margin-left: 10px;
	z-index:999;
}


@media only screen and (max-width: 1400px) {
	nav a, .dropbtn, .logout-btn, .user-info, .navUsername, nav .nav-close-btn{
        color: rgb(255, 255, 255);
    }

	/*
    .dropdown-content {
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
    }
	}*/
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

    
	.dropdown-content {
		background-color: #8b515c;
	}

	.dropdown-content a:hover{
		background-color: #78464f;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 5.0rem;
		background-color: var(--headerColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a{
		font-size: 1.5rem;
	}
}

/*
.user-profile {
    background-color: lavenderblush; 
    color: #563138;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile h1 {
    text-align: center;
    color: #563138; 
    margin-bottom: 20px;
}
*/

/* Style the delete profile button */
.profileDeletion {
    background-color: #563138; /* button background color */
    color: white; /* button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: 1em;
    transition: background-color 0.3s;
}

/* Change background color on hover for the button */
.profileDeletion:hover {
    background-color: red
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .user-profile {
        width: 90%;
        padding: 15px;
    }
}

/* google font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: lavenderblush;
  margin: 0;
  font-family: "Lato";
}
.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* directory */
.directory {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
@media (max-width: 1000px) {
  .directory{
    gap:0px;
  }
}

@media (max-width: 600px) {
  .directory{
    display:block;
  }
}
.listing-details {
  background: #ffffff;
  border-radius: 30px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  border: 3px solid #582c2c;
}

.listing-details img {
  border-radius: 50%;
  width: 100px;  
  height: 100px;  
  object-fit: cover;  
  float: left;  
  margin-right: 20px; 
}

.listing-details .listing-text {
  overflow: hidden;
}

.listing-details::after {
  content: "";
  display: table;
  clear: both;
}

.listing-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color:  #582c2c;
}
.listing-details p {
  margin: 0;
  font-size: 0.9em;
  color: #0b0b0b;
}
.listing-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

.profile-link, .profile-link:hover, .profile-link:visited, .profile-link:active {
  text-decoration: none;
  color: inherit;  
}

.searchBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  border-color:#582c2c;
  border-width: 3px;  
  border-style: solid;  
}

.searchBar button {
  max-width: 500px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background: #563138;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background .3s ease-in-out;
  font-family: "Lato"
}

.searchHeader {
  text-align:center;
  border-color:#582c2c;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px; 
}

.pagination-controls button {
  background-color: #563138;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #985a65; 
}

.pagination-controls button.active {
  background-color: #985a65; 
  border: 2px solid #563138;
}

.pagination-controls button.prev, 
.pagination-controls button.next {
  padding: 8px 16px; 
  font-weight: bold;
}

/* listing form */
label, input, .Selects{
  display: block;
}
.Selects{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius:0.5rem;
}

.black-border-input {
  border: 1px solid black;
  border-radius: 4px; /* Optional, for rounded corners */
}

.inDirectory label{
  font-size:1.2em;
}

.countryOptions button{
  background: #563138;
}

input{
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.pfpUpload {
  color: #fff;
  background-color: #007BFF !important;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top:5px;
  transition: background-color 0.3s ease;
}

.pfpUpload:hover {
  background-color: #0056b3 !important;
}

form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "";
  border-radius: 4px;
  cursor: pointer;
}

.opt-in-statement {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
}


div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

div.success{
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 4px;
  margin: 20px 0;
}

.countrySection::selection{
  color: white;
  background-color: #38b2ac
}
.countrySection::-moz-selection{
  color: white;
  background-color: #38b2ac;
}

.countryHeader {
  font-weight: bold; 
  margin:0;
}

.countryOptions {
  display: flex; 
  flex-wrap: wrap; 
  border-radius: 0.5rem; 
  gap: 0.75rem; 
  margin-bottom:20px;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.country{
  z-index:9998;
}

.state{
  z-index:0;
}

.city {
  z-index:9996;
}

input.error {
  border:1px solid var(--error);
}

.create h6{
  color:#563138;
  font-size: 1.0rem;
}

.create h3{
  color:#563138;
}

.create input{
  font-family: "Lato";
  font-size:1.0rem;
}

.submit-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.opt-in-statement {
  text-align: center;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.listingSubmission,
.saveInProgress {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: white; /* White text for clear readability */
  border-radius: 5px;
}

.listingSubmission {
  background-color: #563138; /* Original dark shade for the submit button */
}

.listingSubmission:hover {
  background-color: #70424B; /* Slightly lighter shade on hover */
}

.saveInProgress {
  background-color: #964c62; /* Teal for the save button */
}

.saveInProgress:hover {
  background-color: #b65d77; /* Slightly darker teal on hover */
}



/* login */

.authLink{
  margin: 20px 0;
}

form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

form.signup button, form.login button{
  background-color: #563138;
}
.checkmark-container {
  font-family: 'Arial', sans-serif;
  color: #563138;
  margin: 10px;
}

.checkmark-container input[type="checkbox"] {
  display: none;
}

.checkmark-container label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.checkmark-container label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #563138;
  border-radius: 3px;
  background-color: white;
}

.checkmark-container input[type="checkbox"]:checked + label:after {
  content: '\2713';
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #563138;
}

/*user profile*/
.user-profile {
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  background-color: #fffdfc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  word-wrap: break-word; /* Ensures text wraps within the container */
}

.user-profile img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px; 
}

.user-details {
  display: flex;
  align-items: flex-start; 
}

.user-text-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h5{
  margin:0;
  padding:0;
}
.user-name {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.3em;
  
}

.user-about, .user-media {
  word-break: break-word; /* Breaks the words to prevent overflow */
  overflow-wrap: hidden; /* Breaks long words/URLs to prevent overflow */
} 

.user-about {
  font-size: 1.2em;
  color: black;
  margin-bottom: 20px;
  margin-top:20px;
  font-weight:normal;
}

.user-categories{
  display: flex;
  flex-wrap: wrap;
  margin-top:20px;
}

.user-email {
  font-size: 1em;
  color: #563138;
  margin-bottom: 20px;
 
}

.user-location{
  color: #333; 
  font-size: 1.2em; 
  font-weight: normal; 
  margin-bottom: 0.5em; 
  margin-top: -10px;
  
}

.category-tag{
  color:rgb(217, 214, 214);
  background-color: #563138;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.user-certifications, .user-skills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.user-certifications > span:first-child, .user-skills > span:first-child, .user-media > span:first-child, .user-languages > span:first-child {
  font-size: 1.3em;
  font-weight: bold;
  margin-right: 10px;
}

.certification-tag, .skill-tag, .language-tag, .media-link {
  color: #fff;
  background-color: #75843c;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.skill-tag{
  background-color: #879945;
}

.language-tag{
  background-color: #5b672e;
}

.user-languages{
  margin-bottom:20px;
}

.user-media {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  align-items: center;
  gap: 10px;
}

.user-media > span:first-child {
  white-space: nowrap; /* Prevents the label from breaking across lines */
  margin-right: 10px; /* Adds some space between the label and the content */
}


.media-link {
  background-color:#292e15;
  display: inline-block; /* Helps with wrapping */
  word-break: break-all; /* Breaks URLs at any character to prevent overflow */
  /*
  margin-right: 10px;
  padding: 4px 8px;
  background-color: #007BFF; 
  color: white;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9em;
  margin-bottom: 10px;
  transition: background-color 0.2s;
  */
}

.user-about > span, .user-media > span {
  word-break: break-word;
  overflow: hidden;
}

.media-link:hover {
  background-color: #563138; 
  display: inline-block; /* Makes links wrap to next line if too long */
  word-break: break-all; /* Optional: Breaks URLs at any character to prevent overflow */
}

.edit-button-container {
  position: absolute;
  top: 10px;
  right: 0px;
  z-index: 10;
}

.profile-content.with-edit-button{
  padding-top:20px;
}

.edit-button {
  background-color: #2d2d2d;
  color: #ffffff;
  border: none;
  padding: 10px 15px;  
  font-size: 16px; 
  border-radius: 8px;  
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #1a1a1a; 
}

.delete-listing-container {
  position: absolute;
  top: 0px; 
  left: 0px;
  z-index: 10;
}

.listingDeletion {
  background-color: #f44336; 
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
      background-color: #d32f2f; 
  }
}

/* aboutpage */
.about-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  /*background-color: #fafafa;*/
  color: #333;
  line-height: 1.6;
}

.about-us-title {
  font-size: 3em;
  color: #563138;
  margin-bottom: 1em;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.about-us-content {
  font-size: 1.5em;
  margin-bottom: 2em;
  transition: all 0.3s ease-in-out;
  text-align: justify;
  line-height: 1.8;
}

.about-us-content:last-child {
  margin-bottom: 0;
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
}

.scroll-animation.show {
  opacity: 1;
  transform: translateY(0);
}


@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@media (max-width: 768px) {
  .about-us-content {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .about-us-flex-container {
    flex-direction: column; 
  }
  .about-us-text, .about-us-image {
    flex: none;
    width: 100%; 
    margin-bottom: 20px; 
  }
  .about-us-image {
    max-width: 100%;
  }
}

.about-us-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
}

.about-us-text, .about-us-image {
  flex: 1; 
}
.about-us-image {
  flex: 1; /* keep the flex property */
  max-width: 50%; /* limit the maximum width to 50% of the container */
  padding: 0 20px; /* add some padding around the image */
  margin-left: 40px; /* Add margin to the right of the image */
}

.about-us-image img {
  width: 100%; /* make the image scale to the width of its container */
  height: auto; /* maintain the aspect ratio of the image */
  max-width: 100%; /* ensure the image does not exceed its natural size */
}

/* forum page*/

.blog-main {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  /*background-image: url('./assets/textured-linen.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.blog-main body {
  color: #222;
}

.blog-main header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.blog-main header a {
  text-decoration: none;
  color: inherit;
}

.blog-main header a.logo {
  font-weight: bold;
  font-size: 1.5rem;
}

div.forum-post {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

div.forum-post div.forum-texts h2.forum-title {
  margin: 0;
  font-size: 1.4rem;
  color: #563138;
}

div.forum-post p.forum-info {
  margin: 10px 0;
  color: #666;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  gap: 20px;
}

div.forum-post p.forum-summary {
  color: #555;
  margin: 10px 0;
  line-height: 1.4rem;
  font-size: 1rem;
}

.forum-summary {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.8em; 
  position: relative;
  padding-right: 1em; 
}

.welcome-description {
  /*background-color: white;*/
  padding: 20px;  
  border-radius: 5px;  
  margin-bottom: 20px;  
  text-align: center;  
  font-size:1.2em;
}

.blog-main h1{
  font-size: 3em;
  color: #563138;
  margin-bottom: 1em;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.welcome-description p {
  margin: 0;  
  font-size: 1em; 
}

.welcome-description a {
  color: #007BFF;  
  text-decoration: underline; 
  transition: color 0.3s ease; 
}

.welcome-description a:hover {
  color: #0056b3;  
}

.create-post-section {
  display: flex;
  justify-content: flex-end; 
  margin-bottom: 20px; 
}

.create-post-btn {
  margin-top:10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #935561; 
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-left: auto; 
}

.create-post-btn:hover {
  background-color: #563138; 
}

.title-input {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #999;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  background-color: transparent; 

}
.title-input:focus{
  outline: none;
  border-color: #007BFF; 
}

.title-input::placeholder{
  color: #999;
  font-style: italic;
}

/* General styling for filter sections */
/* Filter controls container */
.filter-controls {
  display: flex;
  align-items: center;
}

.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.filter-group {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.filter-label {
  color: #582c2c;
  margin-right: 10px;
}

/* Styling the select dropdowns */
.filter-group select {
  padding: 10px;
  border: 0px solid #582c2c;
  background-color: #935561;
  color: white;
  appearance: none;      
  -webkit-appearance: none;  
  -moz-appearance: none; 
  background-position: right center;
  border-radius: 10px;  
}

/* Colors */
.filter-group button {
  background-color: lightpurple; 
  color: white;
}

.filter-group button:hover {
  background-color: #582c2c;
  color: white;
}

/* search bar */
.search-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  border: 1px solid #582c2c;
  border-radius: 20px;
  flex-grow: 1;  
  margin-right: 10px;  
}

/* Search Button */
.search-btn {
  padding: 10px 20px;
  background-color: #935561; 
  border: none;
  border-radius: 25px; 
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px; 
}

.search-btn:hover {
  background-color: #563138; 
}

/*blog like button*/
.like-button {
  background-color: transparent;
  border: none;
  font-size: 20px;  
  cursor: pointer;
  outline: none;
  color: gray;  
}

.like-button .fa-thumbs-up {
  transition: color 0.3s; 
}

.like-button.liked {
  color: green;  
}

.likes-count {
  margin-left: 10px;
  font-size: 14px;
  vertical-align: middle; 
  color: #555; 
}

/* individual blog post */
.post {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;  
}

.iforum-title {
  font-size: 24px;
  margin-bottom: 10px;
  color:#563138;
}

.iforum-info {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
  text-align:center;
}

.iforum-summary {
  margin-top: 20px;
  line-height: 1.5;
}

.iseparator {
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 0;
}

.icomments-section {
  margin-top: 20px;
}

.icomment {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.iforum-author, .iforum-time {
  display: inline-block;
  margin-right: 20px;  
}

.iforum-author::after {
  content: "|";  
  margin-left: 10px; 
  color: #aaa;
}

.iforum-time {
  color: #888;  
}

.iforum-time time {
  font-weight: bold; 
}

/* individual comment styling */
.interaction-section {
  display: flex;
  align-items: center;
  gap: 15px;
}

.like-section {
  display: flex;
  align-items: center;
}

.reply-comment {
  background: none;
  border: none;
  cursor: pointer;
  color: #007BFF;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}

.reply-comment:hover {
  text-decoration: underline;
}

.replies-section {
  margin-left: 20px;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}

.comment-form-section {
  margin-top: 20px; 
}

/* terms and conditions */
.terms-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.terms-container h1, .terms-container h2 {
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.terms-container p {
  margin-top: 20px;
  line-height: 1.6;
  font-size: 16px;
}

.terms-container ol {
  list-style-type: decimal;
  padding-left: 1em; 
}

.terms-container ul {
  list-style-type: disc;
  padding-left: 1em; 
}

/*footer*/
.footer {
  padding: 10px 0; 
  background-color: #f1f1f1;  
  text-align: center;
}

.footer p {
  font-size: 0.8em;  /* Reduces the font size for the footer content */
  margin: 5px 0;  /* Adjusts margin to reduce space between elements */
}

.footer-container {
  background-color: lavenderblush;
  padding: 20px;
  font-family: "Lato", sans-serif;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer a {
  font-size: 0.8em;  /* Reduces the font size for the footer links */
  margin: 0 10px;  /* Horizontal spacing between links */
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #563138;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #935561;
}

.footer-disclaimer {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  font-size: 0.9em;
  color: #777;
}

/*glossary*/
.glossary-container{
  /*background-image: url('./assets/textured-linen.png');*/
  background-color: lavenderblush;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

/*Selector JSX Styles*/
.iDivSelector{
  width: 18rem;
}

.tiDivSelector{
  position:relative;
  margin-top:0.25rem;
}

.triDivSelector {
  position: relative;
  width: 100%;
  cursor: default;
  overflow: hidden;
  border-radius: 0.5rem; /* Equivalent to Tailwind's `rounded-lg` */
  background-color: white;
  text-align: left;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid black;  /* Adding a black border */
}

.triDivSelector:focus:not(:focus-visible) {
  outline: none;
}

.triDivSelector:focus-visible {
  border: 2px solid rgba(255, 255, 255, 0.75); /* Using rgba to set white color with 0.75 opacity */
  box-shadow: 0 0 0 2px #81E6D9; /* Approximation for `ring-offset-teal-300` based on default Tailwind configuration */
}

@media (min-width: 640px) { /* Tailwind's `sm` breakpoint */
  .triDivSelector {
      font-size: 0.875rem; /* Approximation for `text-sm` in default Tailwind config */
  }
}

.oComboBox {
  width: 100%;
  outline: none;
  border: none;
  padding-top: 0.5rem;    /* Tailwind's `py-2` */
  padding-bottom: 0.5rem; /* Tailwind's `py-2` */
  padding-left: 0.75rem;  /* Tailwind's `pl-3` */
  padding-right: 2.5rem;  /* Tailwind's `pr-10` */
  font-size: 0.875rem;    /* Tailwind's `text-sm` */
  line-height: 1.0rem;   /* Tailwind's `leading-5` */
  color: #2d3748;         /* Tailwind's `text-gray-900` */
  position: relative;
  z-index: 0;
  margin-top:20px;
}

.oComboBox::placeholder {
  color: rgb(122, 122, 122); /* Set placeholder text color to black */
  font-family: Lato;
  font-size:1.1em;
}

.oComboBox:focus {
  /* Tailwind's `focus:ring-0` disables the default focus ring in their utilities.
     The same is achieved by the `outline: none` above. No additional styles needed here. */
}

.oCBbutton {
  position: absolute;
  top: 0;             /* `inset-y-0` sets both top and bottom to 0 */
  bottom: 0;          /* `inset-y-0` sets both top and bottom to 0 */
  right: 0;           /* `right-0` sets the right position to 0 */
  display: flex;      /* `flex` sets the display to flex */
  align-items: center;/* `items-center` vertically centers items in the flex container */
  padding-right: 0.5rem; /* Tailwind's `pr-2` */
}

.oChevronud {
  height: 1.25rem;        /* Tailwind's `h-5` */
  width: 1.25rem;         /* Tailwind's `w-5` */
  color: #fefefe;         /* Tailwind's `text-gray-400` */
  transition: color 0.3s; /* Optional: to smoothly transition between color changes on hover */
}

.oChevronud:hover {
  color: lavenderblush;         /* Tailwind's `hover:text-gray-500` */
}

.optionsComboBox {
  position: relative;
  margin-top: 0.25rem;   /* Tailwind's `mt-1` */
  max-height: 15rem;     /* Tailwind's `max-h-60` */
  width: 100%;           /* Tailwind's `w-full` */
  overflow: auto;        /* Tailwind's `overflow-auto` */
  border-radius: 0.375rem; /* Tailwind's `rounded-md` */
  background-color: white; /* Tailwind's `bg-white` */
  padding-top: 0.25rem;  /* Tailwind's `py-1` */
  padding-bottom: 0.25rem; /* Tailwind's `py-1` */
  font-size: 1rem;       /* Tailwind's `text-base` */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* Tailwind's `shadow-lg` */
  border: 1px solid rgba(0, 0, 0, 0.05); /* Combination of `ring-1`, `ring-black`, and `ring-opacity-5` */
  outline: none;         /* Tailwind's `focus:outline-none` */
}

@media (min-width: 640px) { /* Tailwind's `sm` breakpoint */
  .optionsComboBox {
      font-size: 0.875rem; /* Tailwind's `sm:text-sm` */
  }
}

.fourSelectorDiv {
  position: relative;
  cursor: default;
  user-select: none;        /* Equivalent to Tailwind's `select-none` */
  padding-top: 0.5rem;      /* Tailwind's `py-2` */
  padding-bottom: 0.5rem;   /* Tailwind's `py-2` */
  padding-left: 1rem;       /* Tailwind's `px-4` */
  padding-right: 1rem;      /* Tailwind's `px-4` */
  color: #4A5568;           /* Tailwind's `text-gray-700` */
}

.ofPSelector {
  position: relative;
  cursor: default;
  user-select: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 1rem;
  color: #2D3748; /* Default to Tailwind's `text-gray-900` */
}

.ofPSelector.active {
  background-color: #582c2c; /* Tailwind's `bg-teal-600` */
  color: white; /* Tailwind's `text-white` */
}

.OspanSelectorCB {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400; /* defaulting to font-normal */
}

.OspanSelectorCB.selected {
  font-weight: 500; /* for font-medium */
}

.TspanSelectorCB {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  color: #582c2c;  /* defaulting to text-teal-600 */
}

.TspanSelectorCB.active {
  color: white;  /* for text-white */
}

.checkIconSelector {
  height: 1.25rem;
  width: 1.25rem;
}
/* GreenBurialInfo.css */

.green-burial-info {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  background-color: lavenderblush;
  color: #582c2c;
  line-height: 1.6;
}

.green-burial-info h1 {
  font-size: 3em;
  color: #563138;
  margin-bottom: 1em;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.green-burial-info h2{
  font-size: 2em;
  color: #563138;
  margin-bottom: 1em;
  transition: all 0.3s ease-in-out;
  text-align: left;
}

.green-burial-info h3{
  font-size: 1.5em;
  color: #563138;
  margin-bottom: 1em;
  transition: all 0.3s ease-in-out;
  text-align: left;

}


.green-burial-info p {
  font-size: 1.5em;
  margin-bottom: 2em;
  text-align: justify;
  transition: all 0.3s ease-in-out;
  line-height: 1.8;
}

.green-burial-info ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.green-burial-info li {
  display: inline-block;
  margin: 0 10px 10px;
}

.green-burial-info a {
  color: #985a65;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.green-burial-info a:hover {
  color: #582c2c;
}

.green-burial-info .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}   

.green-burial-info .video-container iframe {
  border: none; 
}

@media (max-width: 768px) {
  .green-burial-info p, 
  .green-burial-info ul {
    text-align: left;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


.home-page .hero-section {
  background-image: url("../assets/home1.png"); 
  background-size: cover;
  background-position: center;
  min-height: 75vh;
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-left: 40px;
}

.home-page .hero-section p {
  margin: 0;
  padding: 0;
  max-width: 450px;
}

.hero-section h1{
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 60px;
  color: #563138; 
  margin-top:20px;
  max-width: 600px;
  margin-bottom:10px;
}

.home-page .hero-section.scrolled {
  background-color: #582c2c;
  background-image: none; 
  transition: background-color 0.3s; 
}



.hero-section label{
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.hero-section p{
  font-family: 'Open Sans', sans-serif;
  font-size: 25px;
  color: #563138;
}

.HsearchBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top:30px;
  max-width: 100%;
}

.HsearchBar button {
  max-width: 500px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background: #563138;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background .3s ease-in-out;
  font-family: "Lato";
  font-weight:bold;

}

.Hsearchbutton{
  margin-bottom: 20px;
}

@media (max-width: 1115px){
  .hero-section h1{
    font-size: 50px;
  }
}

@media (max-width: 954px){
  .hero-section h1{
    font-size: 45px;
    max-width: 400px;
  }

  .hero-section p{
    font-size:20px;
  }

  .home-page .hero-section p{
    max-width:350px;
  }
}

@media (max-width: 800px) {
  .home-page .hero-section {
    background-image: url("../assets/homeLights.png"); 
    padding-right:50px;
  }

  .hero-section h1{
    text-align: center;
    max-width:100%;
  }

  .hero-section p{
    font-size:22px;
  }

  .home-page .hero-section p{
    max-width:100%;
    text-align:center;
    padding-left:0;
    margin-left:0;
  }
  
  .HsearchBar {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 15px;
    margin-top: 30px;
    max-width: 300px; 
    margin-left: auto;
    margin-right: auto;
    width: 100%; 
  }


}

@media (max-width: 443px) {
  .hero-section h1{
    font-size: 40px;
  }

  
}

@media (max-width: 435px) {

  .hero-section h1{
    font-size: 32px;
  }

  .hero-section p{
    font-size:16px;
  }

  .home-page .hero-section {
    min-height: 55vh;
  }
}

.HSelects{
  margin-top: 10px;
  margin-bottom: 20px;
  width: 290px;
  box-sizing: border-box;
  border-radius:0.5rem;

}
.home-page .content-section {
  background-color: #582c2c;
  min-height:50vh;
  align-content: center;
  overflow-x: hidden;
}

.content-section h1, .content-section p{
  color:white;
}

.content-section p{
  font-family: 'Open Sans', sans-serif;
  font-size:28px;
}

.content-section a{
  color:lavenderblush;
  transition: 0.3s ease-in-out; 
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 40px;
}

.section .image-container {
  transition: transform 0.3s ease-in-out;
}

.section .text-container {
  padding: 20px;
}

.section-1 .text-container,
.section-3 .text-container {
  margin-left: 20px; 
}

/* Common styles for image and text containers */
.section .image-container,
.section .text-container {
  flex: 1; 
  padding: 10px;
}

/* Hover effect for images */
.section:hover .image-container img {
  transform: scale(1.05);
}



/* Style for images */
.image-container img {
  width: 100%; 
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.5s ease-in-out; /* Smooth transition for transform */
}
.section-1{
  padding-top:120px;
}
/* Alternate the order for image and text */
.section-1 .image-container,
.section-3 .image-container {
  order: 1; /* Image first */
}

.section-1 .text-container,
.section-3 .text-container {
  order: 2; /* Text second */
}

.section-2 .image-container {
  order: 2; /* Image second */
}

.section-2 .text-container {
  order: 1; /* Text first */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .section .image-container {
    order: 1;
  }

  /* Ensure text always comes second */
  .section .text-container {
    order: 2;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .section {
    scroll-behavior: smooth;
  }
}
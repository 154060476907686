/* calendarForm.css */

/* Form container */
.addCalendarEvent form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lavenderblush;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Label styles */
  .addCalendarEvent label {
    font-weight: bold;
    color: #563138;
    margin-top: 10px;
  }
  
  /* Input styles */
  .addCalendarEvent input[type="text"],
  .addCalendarEvent input[type="date"],
  .addCalendarEvent input[type="time"],
  .addCalendarEvent textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #563138;
    border-radius: 5px;
    background-color: white;
    color: #563138;
    outline: none;
  }
  
  /* Button style */
  .addCalendarEvent button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #563138;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .addCalendarEvent button[type="submit"]:hover {
    background-color: #935561; /* Darker shade of #563138 */
  }
  
  /* Error message styles */
  .addCalendarEvent .error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Additional styles for a fancy look (you can adjust as needed) */
  .addCalendarEvent textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    form {
      width: 90%;
    }
  }
  